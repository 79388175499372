<template>
  <div id="v" class=" items-center scroll p-3 justify-center inset-0 ...">
    <table style=" height: 70%" class="text-center w-full shadow-md bg-white">
      <tr class="HeaderTableColor">
        <th class=" text-center" style="width:30%" v-if="IsAppointemt">
          {{ $t("YouNextOnlineAppointment") }}
        </th>
        <th class=" text-center" style="width:30%" v-else>

          {{ $t("Surgeries") }}
        </th>
        <th class=" w-50 text-center" style="width:30%">
          {{ $t("Date") }}
        </th>

        <th class="text-center" style="width:30%" v-if="IsAppointemt">{{ $t("Doctor") }}</th>
        <th class=" text-center" style="width:30%" v-else>{{ $t("Hospital") }}</th>
        <th class="text-center downloadCell" style="width:10%">

        </th>
      </tr>
      <tr v-for="(item, index) in Model" :key="index">
        <td>
          <div v-if="IsAppointemt">
            {{ $t("OnlineAppointment") }}

          </div>
          <div v-if="!IsAppointemt">
            {{ item.SurgeryName }}
          </div>
          <div >
             <p >  <span style="color:red"  > {{$t('HoursLeft')}}: </span> {{ item.PaymentHourLeft}} </p>
            <p > <span style="color:red" >{{$t('MinutsLeft')}}:</span>  {{ item.PaymentMinutsLeft}}</p>
          </div>
        </td>
        <td v-if="IsAppointemt">{{ item.DateFormatted }}</td>
        <td v-if="!IsAppointemt">{{ item.DateFromTo }}</td>

        <td id="a" style="max-width: 20%;margin-right:5px">
          <div v-if="IsAppointemt">
            <imageLazy
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.DoctorImage"
                      placeHolderType="doctor"
                    />
          </div>
           <p v-if="IsAppointemt"> {{$t("Dr")}}.{{ item.DoctorName }} </p>
             <p v-if="!IsAppointemt"> {{ item.HospitalName }} </p>
        </td>

        <td class="bg-danger text-white downloadCell" style="max-width:50px">
          <div  class="handclick"  @click="PayNow(item)">
            <feather-icon
              icon="DollarSignIcon"
              svgClasses="h-4 w-4"
              class="ml-1"
            />
          <p style="font-size:15px">{{ $t("PayNow") }}</p>
          </div>

        </td>
      </tr>
       <tr v-if="Model.length==0">
        <td></td>
        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>
          </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components:{
    imageLazy
  },

  data() {
    return {
      baseURL: domain,

    };
  },
   props: {
    Model: {
      type: Array,
      required: true,
    },
    IsAppointemt: {
      Boolean: true,
      required: true,
    }
   },
  methods:{
     RefershGrid(){
    this.$emit("getPatientPendingList");
    },
  PayNow(data) {
        if(data.PatientReservedSessionID)
        {
          var reservation={"ID":data.PatientReservedSessionID};
          reservation.FrontUrl = window.location.origin
          this.$store
            .dispatch("patientList/AppointmentReservationPaymentDatail", reservation)
            .then((res) => {
              debugger;
              window.location.href  = res.data.Data.transaction.url;
              // document.getElementById("amount").value = res.data.Data.amount;
              // document.getElementById("customer_email").value =
              //   res.data.Data.customer_email;
              // document.getElementById("signature").value = res.data.Data.signature;
              // document.getElementById("merchant_reference").value =
              //   res.data.Data.merchant_reference;

              // document.getElementById("paymentForm").submit();
            });
            this.RefershGrid();
        }
        else
        {
            this.$router.push("/Patient/Surgeryprofile/"+data.PatientReservedSurgeryID)
        }

    },
  },

  mounted() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

  },

};
</script>
<style>
.downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#v table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#v td,
#v th {
  border-left: solid #00000029 1px;
  border-top: solid hsla(0, 0%, 0%, 0.161) 1px;
}

#v th {
  border-top: none;
}

#v th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#v th:last-child {
  border-top-right-radius: 36px;
}
#v tr:last-child td:last-child {
  border-bottom-right-radius: 36px;

}
#v td:first-child,
#v th:first-child {
  border-left: none;
}
#v th,
tr {
  height: 5rem;
}
.scroll{
overflow-x:auto !important
}
</style>
